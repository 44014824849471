<template>
  <NameAndJobTitle />
  <AboutMe />
</template>

<script>
import NameAndJobTitle from "./NameAndJobTitle.vue";
import AboutMe from "./AboutMe.vue";


export default {
  components: {
    NameAndJobTitle,
    AboutMe,
  },
};
</script>

<style>
</style>