<template>
  <div class="stage">
    <div class="text-to-reveal" :class="{ 'reveal-animation': reveal }">
      Remy Jouni
    </div>
    <div class="shroud"></div>
  </div>
</template>

<script>
export default {
  props: {
    timeDelay: { type: Number, required: true },
  },
  data() {
    return { reveal: false };
  },
  methods: {
    revealText(timeout) {
      setTimeout(() => {
        this.reveal = true;
      }, timeout);
    },
  },
  mounted() {
    const timeDelay = this.timeDelay;
    this.revealText(timeDelay);
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/Responsive/breakpoints.scss";
.stage {
  position: relative;
  z-index: 1;
  width: 100%;
}

.text-to-reveal {
  font-family: "Valencia";
  text-align: center;
  opacity: 0;
  @media (min-width: $tablet) {
    font-size: 100px;
  }
  @media (min-width: $laptop) {
    font-size: 140px;
  }
  @media (min-width: $laptopL) {
    font-size: 150px;
  }
  @media (min-width: $desktop) {
    font-size: 200px;
  }
}
.reveal-animation {
  opacity: 1;
  @media (min-width: $tablet) {
    font-size: 100px;
    animation: moveUpTablet 1s cubic-bezier(0, 0.1, 0.12, 0.99) forwards;
    transform: translateY(140px);
  }
  @media (min-width: $laptop) {
    font-size: 140px;
    animation: moveUpLaptop 1s cubic-bezier(0, 0.1, 0.12, 0.99) forwards;
    transform: translateY(196px);
  }
  @media (min-width: $laptopL) {
    font-size: 150px;
    animation: moveUpLaptopL 1s cubic-bezier(0, 0.1, 0.12, 0.99) forwards;
    transform: translateY(210px);
  }
  @media (min-width: $desktop) {
    font-size: 200px;
    animation: moveUpDesktop 1s cubic-bezier(0, 0.1, 0.12, 0.99) forwards;
    transform: translateY(280px);
  }
}

.shroud {
  position: absolute;
  background-color: var(--background);
  margin-right: 100%;
  width: 120%;
  height: 35vh;
  animation: hideShroud 0.5s linear forwards;
  animation-delay: 2s;
}

@keyframes hideShroud {
  0% {
    opacity: 1;
    height: 35vh;
  }
  100% {
    opacity: 0;
    height: 0vh;
  }
}

@keyframes moveUpTablet {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveUpLaptop {
  0% {
    transform: translateY(140px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveUpLaptopL {
  0% {
    transform: translateY(150px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveUpDesktop {
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>