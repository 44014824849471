<template>
  <section class="work-container">
    <TextContent
      :number="workDetails[slideNumber].number"
      :projectName="workDetails[slideNumber].projectName"
      :projectDesc="workDetails[slideNumber].projectDesc"
      :LinkText="workDetails[slideNumber].LinkText"
      :LinkURL="workDetails[slideNumber].LinkURL"
      :projectType="workDetails[slideNumber].projectType"
      :roles="workDetails[slideNumber].roles"
      :modal="workDetails[slideNumber].modal"
    />

    <ImageContent :pageSplitTimes="pageSplitTimes" />
  </section>
</template>

<script>
import ImageContent from "./ImageContent.vue";
import TextContent from "./TextContent.vue";

import CV from "../../../assets/Documents/Remy_Jouni_CV.pdf";
import MushrfeBooklet from "../../../assets/Documents/Mushrfe_Booklet.pdf";

export default {
  name: "work",
  components: {
    ImageContent,
    TextContent,
  },
  data() {
    return { vh: 0, slideNumber: 0 };
  },
  methods: {
    handleScroll(event) {
      const { body, documentElement } = event.srcElement;
      const { vh, slideNumber } = this;
      const scrollDistance = Math.max(
        body.scrollTop,
        documentElement.scrollTop
      );

      if (scrollDistance > this.lastScrollTop) {
        this.scrollDirectionDown = true;
      } else {
        this.scrollDirectionDown = false;
      }

      this.lastScrollTop = scrollDistance;

      // console.log(scrollDistance);
      if (
        Math.floor(scrollDistance / vh) !== slideNumber &&
        slideNumber < this.workDetails.length - 1
      ) {
        this.slideNumber = Math.floor(scrollDistance / vh);
      } else if (
        slideNumber === this.workDetails.length - 1 &&
        Math.floor(scrollDistance / vh) < slideNumber
      ) {
        this.slideNumber = Math.floor(scrollDistance / vh);
      }
    },
  },
  created() {
    this.pageSplitTimes = 1.4;
    this.lastScrollTop = 0;
    this.scrollDirectionDown = true;
    this.workDetails = [
      {
        number: "themeToggle",
        projectName: "",
        projectDesc: "",
        projectType: "",
        LinkText: "Download CV",
        LinkURL: CV,
        roles: [""],
        modal: false,
      },
      {
        number: "01",
        projectName: "Mushrfe Kiosk",
        projectDesc:
          "An app for a self-service kiosk machine that enables the desk-less workforce to communicate directly with HR department to apply for requests and receive services in return.",
        LinkText: "Download Brochure",
        LinkURL: MushrfeBooklet,
        projectType: "WEB APP",
        roles: ["UI Designer"],
        modal: false,
      },
      {
        number: "02",
        projectName: "Zoo Application",
        projectDesc:
          "iOS app concept with a map interface that helps visitors get directions to animals, places to eat, and other places of interest.",
        LinkText: "View Case Study",
        LinkURL: "https://www.behance.net/gallery/130534477/Zoo-App-Case-Study",
        projectType: "iOS APP",
        roles: ["UX Designer"],
        modal: true,
      },
      {
        number: "03",
        projectName: "Medication Reminder",
        projectDesc:
          "Android app concept to remind people to take their medication on time, and to manage their inventory of medicine. ",
        LinkText: "View Case Study",
        LinkURL:
          "https://www.behance.net/gallery/131314293/Medication-Reminder-Case-Study",
        projectType: "WEB APP",
        roles: ["UX Designer"],
        modal: true,
      },
      {
        number: "04",
        projectName: "Taka News App",
        projectDesc:
          "News aggregation app for the Arab-World that provide users with articles that interestes them.",
        LinkText: "View Project",
        LinkURL: "https://www.behance.net/gallery/89761985/Taka-News-App",
        projectType: "Android / iOS APP",
        roles: ["Front-end Developer", "UX Designer"],
        modal: true,
      },
      {
        number: "05",
        projectName: "Taka News Website",
        projectDesc:
          "News aggregation website for the Arab World with a similar feature set as the application previously mentioned.",
        LinkText: "View Project",
        LinkURL: "https://www.behance.net/gallery/91337175/Taka-News-Website",
        projectType: "WEB APP",
        roles: ["Front-end Developer", "UX Designer"],
        modal: true,
      },
      {
        number: "06",
        projectName: "Shura System",
        projectDesc:
          "A knowledge base system with communication and file management features that enables users to collaborate in the best way possible",
        LinkText: "View Project",
        LinkURL: "https://www.behance.net/gallery/89826353/SHURA-SYSTEM-v80",
        projectType: "WEB APP",
        roles: ["Front-end Developer", "UX Designer"],
        modal: true,
      },
      {
        number: "07",
        projectName: "Telework Pro",
        projectDesc:
          "A communicating and time tracking system that empowers users while working remotely. ",
        LinkText: "",
        LinkURL: "",
        projectType: "WEB APP",
        roles: ["Front-end Developer", "UX Designer"],
        modal: false,
      },
      {
        number: "",
        projectName: "",
        projectDesc: "",
        LinkText: "",
        LinkURL: "",
        projectType: "",
        roles: [""],
        modal: false,
      },
    ];
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.vh = Math.round(
      window.document.documentElement.clientHeight * this.pageSplitTimes
    );
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.work-container {
  display: flex;
  flex-flow: row nowrap;
  background: var(--background);
}
</style>