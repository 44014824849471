<template>
  <section class="container">
    <NameReveal :timeDelay="500" />
    <br />
    <TitleReveal :timeDelay="1300" />
  </section>
</template>

<script>
import NameReveal from "./NameReveal.vue";
import TitleReveal from "./TitleReveal.vue";

export default {
  components: {
    NameReveal,
    TitleReveal,
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--background);
  color: var(--header-text);
}
</style>