<template>
  <section class="container">
    <div class="name">Remy Jouni</div>

    <div class="title">
      <div :class="{ 'type-writing': reveal }">
        Front-end Developer & UX Designer
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return { reveal: false };
  },
  methods: {
    revealText(timeout) {
      setTimeout(() => {
        this.reveal = true;
      }, timeout);
    },
  },
  mounted() {
    this.revealText(1500);
  },
};
</script>


<style lang="scss" scoped>
@import "../../../assets/Responsive/breakpoints.scss";

.container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 40%;
  width: 100%;
  background-color: var(--background);
  color: var(--header-text);
  position: relative;
}
.name {
  font-family: "Valencia";
  text-align: center;
  padding-right: 10px;
  @media (min-width: $mobileS) {
    font-size: 70px;
  }
  @media (min-width: $mobileM) {
    font-size: 80px;
  }
  @media (min-width: $mobileL) {
    font-size: 90px;
  }
  @media (min-width: $tablet) {
    font-size: 150px;
  }
  @media (min-width: $laptop) {
    font-size: 160px;
  }
}

.title {
  font-family: "AvenirRoman";
  text-align: center;
  margin-top: 10px;
  visibility: hidden;
  color: var(--text);
  width: fit-content;
  @media (min-width: $mobileS) {
    font-size: 15px;
  }
  @media (min-width: $mobileM) {
    font-size: 17px;
  }
  @media (min-width: $mobileL) {
    font-size: 19px;
  }
  @media (min-width: $tablet) {
    font-size: 30px;
  }
  @media (min-width: $laptop) {
    font-size: 35px;
  }
}

.type-writing {
  overflow: hidden;
  border-right: 0.15em solid var(--header-text);
  white-space: nowrap;
  margin: 0 auto;

  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  visibility: visible;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--header-text);
  }
}
</style>