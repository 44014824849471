<template>
  <section class="text-container">
    <div class="project-id">
      <button
        class="theme-toggle--button"
        aria-label="Toggle Theme"
        v-if="number == 'themeToggle'"
        @click="toggleTheme"
      >
        <span class="shape" :class="[darkTheme ? 'sun' : '', 'moon']"></span>
        <span class="rays--container">
          <span class="ray"></span>
          <span class="ray"></span>
          <span class="ray"></span>
          <span class="ray"></span>
        </span>
      </button>
      <div class="block-text" v-else>
        {{ number }}
      </div>
    </div>
    <div class="project-details-container">
      <div class="project-details">
        <div class="project-name">
          <div class="block-text">
            {{ projectName }}
          </div>
        </div>
        <div class="my-role">
          <div class="block-text">
            <span v-for="(role, index) in roles" :key="role">
              <span v-if="index === roles.length - 1">
                {{ role }}
              </span>
              <span v-else>
                {{ role }}
                &nbsp; • &nbsp;
              </span>
            </span>
          </div>
        </div>
        <div class="project-desc">
          <div class="block-text" style="display: block">
            {{ projectDesc }}
          </div>
        </div>
      </div>
    </div>

    <ProjectImages
      :projectImgs="projectImgs"
      :style="
        number == 'themeToggle' || number == ''
          ? 'visibility:hidden'
          : 'visibility:visible'
      "
    />

    <div class="project-link">
      <a class="block-link" :href="LinkURL" target="_blank" v-if="!modal">
        {{ LinkText }}
      </a>
      <a class="block-link" v-if="modal" @click="toggleModal">
        {{ LinkText }}
      </a>
    </div>
  </section>

  <!--? Modal -->
  <div
    class="custom-modal"
    :class="{ show: showModal }"
    @click.self="toggleModal"
  >
    <div class="close-modal" @click="toggleModal">&#10006;</div>

    <div class="custom-modal-body">
      <div class="custom-modal-content">
        <ZooAppDetails v-if="number == '02' && showModal" />
        <MedicationReminderDetails v-if="number == '03' && showModal" />
        <TakaAppDetails v-if="number == '04' && showModal" />
        <TakaWebDetails v-if="number == '05' && showModal" />
        <ShuraDetails v-if="number == '06' && showModal" />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectImages from "./ProjectImages.vue";

import MedicationReminderDetails from "../../../ModalContent/MedicationReminderDetailsl.vue";
import ZooAppDetails from "../../../ModalContent/ZooAppDetails.vue";
import TakaAppDetails from "../../../ModalContent/TakaAppDetails.vue";
import TakaWebDetails from "../../../ModalContent/TakaWebDetails.vue";
import ShuraDetails from "../../../ModalContent/ShuraDetails.vue";

export default {
  name: "text-content",

  components: {
    ProjectImages,
    MedicationReminderDetails,
    ZooAppDetails,
    TakaAppDetails,
    TakaWebDetails,
    ShuraDetails,
  },
  props: {
    number: { type: String, required: true },
    projectName: { type: String, required: true },
    projectDesc: { type: String, required: true },
    projectType: { type: String, required: true },
    LinkText: { type: String, required: true },
    LinkURL: { type: String, required: true },
    roles: { type: Array, required: true },
    modal: { type: Boolean, required: true },
    projectImgs: { type: Array, required: true },
  },
  data() {
    return {
      showModal: false,
      darkTheme: true,
    };
  },

  mounted() {
    let link = document.querySelector(".block-link");
    link.classList.add("link-text-reveal");
  },

  updated() {
    let list = document.querySelectorAll(".block-text");

    for (let i = 0; i < list.length; ++i) {
      list[i].classList.remove("block-text-reveal");
      void list[i].offsetWidth; //  a trick to restart the animation
      list[i].classList.add("block-text-reveal");
    }

    let link = document.querySelector(".block-link");
    link.classList.remove("link-text-reveal");
    void link.offsetWidth; //  a trick to restart the animation
    link.classList.add("link-text-reveal");
  },

  methods: {
    toggleModal() {
      this.showModal = !this.showModal;

      //preventing the main poge from scrolling when the modal is open
      let body = document.querySelector("body");
      if (this.showModal) {
        body.classList.add("no-scroll");
      } else {
        body.classList.remove("no-scroll");
      }
    },
    toggleTheme() {
      this.darkTheme = !this.darkTheme;
      let body = document.querySelector("body");

      if (!this.darkTheme) {
        body.classList.add("light-theme");
      } else {
        body.classList.remove("light-theme");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/Responsive/breakpoints.scss";

.text-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  /* border: 1px dashed black; */
  height: 100%;
  width: 100%;
}

.project-details {
  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  padding: 5%;

  padding-left: 10%;
}

.project-details-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  // padding-top: 5%;
  // height: 100%;
}

.project-name {
  font-family: "AvenirHeavy";
  @media (min-width: $mobileS) {
    font-size: 40px;
  }
  @media (min-width: $mobileM) {
    font-size: 45px;
  }
  @media (min-width: $mobileL) {
    font-size: 50px;
  }
  @media (min-width: $tablet) {
    font-size: 60px;
  }
  @media (min-width: $laptop) {
    font-size: 90px;
  }
}

.project-desc {
  padding-top: 2%;
  font-family: "AvenirBook";

  @media (min-width: $laptopL) {
    font-size: 30px;
  }
  @media (min-width: $desktop) {
    font-size: 50px;
  }
}

.my-role {
  padding-top: 5%;
  font-family: "AvenirMedium";

  @media (min-width: $mobileM) {
    font-size: 18px;
  }
  @media (min-width: $mobileL) {
    font-size: 24px;
  }
  @media (min-width: $laptopL) {
    font-size: 30px;
  }
  @media (min-width: $desktop) {
    font-size: 50px;
  }
}

.project-id {
  font-family: "AvenirHeavy";
  padding: 5%;

  @media (min-width: $laptopL) {
    font-size: 30px;
  }
  @media (min-width: $desktop) {
    font-size: 58px;
  }
}

.project-type {
  font-family: "AvenirHeavy";
  margin-top: auto;

  @media (min-width: $laptopL) {
    font-size: 30px;
  }
  @media (min-width: $desktop) {
    font-size: 58px;
  }
}

.project-link {
  font-family: "AvenirHeavy";
  padding: 5%;
  text-decoration: underline;

  color: var(--header-text);

  @media (min-width: $laptop) {
    font-size: 25px;
  }
  @media (min-width: $laptopL) {
    font-size: 30px;
  }
  @media (min-width: $desktop) {
    font-size: 58px;
  }
}
.block-link {
  cursor: pointer;
}

.block-text-reveal {
  display: inline;
  color: var(--background);
  animation: appearText 0.0001s linear forwards;
  animation-delay: 0.5s;
  position: relative;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 0%;
    height: 100%;
    background: var(--text-block);
    animation: revBlock 1s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    animation-delay: 0s;
  }
}

.link-text-reveal {
  display: inline;
  color: var(--background);
  animation: appearLink 0.0001s linear forwards;
  animation-delay: 0.5s;
  position: relative;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 0%;
    height: 100%;
    background: var(--text-block);
    animation: revBlock 1s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    animation-delay: 0s;
  }
}

@keyframes appearText {
  0% {
    color: var(--background);
  }
  100% {
    color: var(--text);
  }
}

@keyframes appearLink {
  0% {
    color: var(--background);
  }
  100% {
    color: var(--header-text);
  }
}

@keyframes revBlock {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 0%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

// MODAL
.custom-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  overscroll-behavior: contain;
  .close-modal {
    position: absolute;
    top: 16px;
    right: 16px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    border-radius: 50%;
    cursor: pointer;
    // margin-left: -8px;
    color: #064663;
    background: var(--header-text);
    transition: all 0.2s ease;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    &:hover {
      filter: brightness(97%);
    }
    &:active {
      filter: brightness(94%);
    }
  }
  .custom-modal-body {
    overscroll-behavior: contain;
    background: #fff;
    // border-radius: 16px;
    max-width: 1400px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    position: relative;
    box-shadow: 4px 4px 15px rgba(53, 49, 117, 0.2);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

    .custom-modal-header {
      border-bottom: 1px solid var(--background);
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      background: var(--text-block);
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;

      .custom-modal-title {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  &.show {
    visibility: visible;
    opacity: 1;

    .custom-modal-body {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
}

.no-scroll {
  overflow: hidden !important;
}

// Theme Toggle
.theme-toggle--button {
  --button-diameter: 42px;
  --shape-size: 14px;
  --color: var(--text);
  border: none;
  outline: none;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  align-items: center;
  background-color: var(--text-block);
  justify-content: center;
  transition: box-shadow 500ms;
  font-size: var(--shape-size);
  width: var(--button-diameter);
  height: var(--button-diameter);
  box-shadow: 4px 4px 15px rgba(53, 49, 117, 0.2);
  &:focus {
    -webkit-tap-highlight-color: transparent;
  }
}
.dark ~ .theme-toggle--button {
  box-shadow: 4px 4px 15px rgb(29 27 58);
}
.theme-toggle--button span {
  display: inline-block;
}
.theme-toggle--button .rays--container {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.theme-toggle--button .rays--container .ray {
  position: absolute;
  border-radius: 50%;
  transition: all 500ms;
}
.theme-toggle--button .rays--container .ray:nth-child(2) {
  transform: rotate(45deg);
}
.theme-toggle--button .rays--container .ray:nth-child(3) {
  transform: rotate(90deg);
}
.theme-toggle--button .rays--container .ray:nth-child(4) {
  transform: rotate(-45deg);
}
.theme-toggle--button .shape {
  z-index: 1;
  position: absolute;
  border-radius: 50%;
  background: var(--text-block);
  border: 2px solid var(--text-block);
  transition: all 500ms;
}
.theme-toggle--button .shape.moon {
  width: 1.667em;
  height: 1.667em;
  box-shadow: inset -0.5em -0.5em var(--color);
}
.theme-toggle--button .shape.moon ~ .rays--container .ray {
  width: 0;
  height: 0;
}
.theme-toggle--button .shape.sun {
  width: 1em;
  height: 1em;
  box-shadow: inset -0.7em -0.7em var(--color);
}
.theme-toggle--button .shape.sun ~ .rays--container {
  transform: rotate(90deg);
  transition: transform 750ms 400ms;
}
.theme-toggle--button .shape.sun ~ .rays--container .ray {
  width: 0.334em;
  height: 1.667em;
  background-color: var(--color);
}
</style>


<style>
.light-theme {
  --background: #eeebec;
  --text-block: hsl(338, 4%, 80%);
  --light-text: hsl(338, 5%, 90%);
  --header-text: hsl(32, 78%, 66%);
  --text: hsl(338, 85%, 11%);
}
</style>

 
 