<template>
  <section class="about-me">
    <div class="about-me-title"
      :style="{ transform: `translateX(${scrollPercent * 5.5}%)` }"
      >ABOUT ME</div
    >
    <div class="about-me-description">
      <p>
        Front-end Developer & UX Designer with 8+ years of experience where I
        designed and developed multiple systems.
      </p>
      <p>
        I have Muscular Dystrophy which forces me to only be able to work
        remotely and part-time. But I won't allow that to hinder me from
        reaching my potential and fulfilling my dreams.
      </p>
    </div>
  </section>
</template>

<script>
 

export default {
 
  data() {
    return { scrollPercent: 0 };
  },
  methods: {
    handleScroll(event) {
      const { body, documentElement } = event.srcElement;
      const sd = Math.max(body.scrollTop, documentElement.scrollTop);
      const sp =
        (sd / (documentElement.scrollHeight - documentElement.clientHeight)) *
        100;
      const maxlimit =
        (documentElement.clientHeight * 150) / documentElement.scrollHeight;

      if (sp >= 0 && sp <= maxlimit) {
        this.scrollPercent = sp;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/Responsive/breakpoints.scss";
.about-me {
  height: 40vh; /* Since pageSplitTime is 1.4 */
  width: 100%;
  /* border: 1px solid blue; */
  position: relative;
  overflow: hidden;
  background: var(--background);
  display: flex;
  align-items: center;
}

.about-me-title {
  transition: transform 0.5s ease-out;
  font-family: "AvenirHeavy";
  position: absolute;
  color: var(--light-text);

  top: 0%;
  left: -15%;
  @media (min-width: $laptop) {
    font-size: 180px;
  }
  @media (min-width: $laptopL) {
    font-size: 200px;
  }
  @media (min-width: $desktop) {
    font-size: 350px;
  }
}

.about-me-description{
  align-items: center;
  font-family: "AvenirLight";
  text-align: left;
  margin-left: 24%;
  margin-right: 5%;
  color: var(--text);
  z-index: 0;
  position: relative;
  @media(min-width: $laptop) {
    // transform: translateY(80%);
    font-size: 30px;
  }
  @media (min-width: $laptopL) {
    // transform: translateY(77%);
    font-size: 38px;
  }
  @media (min-width: $desktop){
    // transform: translateY(70%);
    font-size: 70px;
  }
}


</style>