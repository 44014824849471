<template>
  <img
    :src="firstImgLink"
    alt="animals Case Study mobile UI ui design UI/UX ux zoo map mobile app design"
    loading="lazy"
    class="modal-img"
  />

  <img
    :src="secondImgLink"
    alt="animals Case Study mobile UI ui design UI/UX ux zoo map mobile app design"
    loading="lazy"
    class="modal-img"
  />

  <img
    :src="thirdImgLink"
    sizes="(max-width: 1400px) 100vw, 1400px"
    alt="animals Case Study mobile UI ui design UI/UX ux zoo map mobile app design"
    loading="lazy"
    class="modal-img"
  />

  <img
    :src="fourthImgLink"
    alt="animals Case Study mobile UI ui design UI/UX ux zoo map mobile app design"
    loading="lazy"
    class="modal-img"
  />

  <img
    :src="fifthImgLink"
    alt="animals Case Study mobile UI ui design UI/UX ux zoo map mobile app design"
    loading="lazy"
    class="modal-img"
  />
  <div class="modal-iframe">
    <iframe
      src="https://www.figma.com/embed?embed_host=share&amp;url=https%3A%2F%2Fwww.figma.com%2Fproto%2FfCoHIsunq6B8yw5qzC9N2F%2FZoo-Map-App%3Fpage-id%3D133%253A538%26node-id%3D134%253A539%26viewport%3D241%252C48%252C0.12%26scaling%3Dscale-down%26starting-point-node-id%3D134%253A539"
      title="Project Embed Content"
      frameborder="0"
      allowfullscreen="allowfullscreen"
      class="embed-content"
      style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%"
    ></iframe>
  </div>

  <img
    :src="sixthImgLink"
    alt="animals Case Study mobile UI ui design UI/UX ux zoo map mobile app design"
    loading="lazy"
    class="modal-img"
  />
</template>

<script>
import firstImg from "../assets/Images/modals/ZooApp/0-Splash.webp";
import secondImg from "../assets/Images/modals/ZooApp/1-Overview.webp";
import thirdImg from "../assets/Images/modals/ZooApp/2-Understanding_the_User.webp";
import fourthImg from "../assets/Images/modals/ZooApp/3-Starting_the_design.webp";
import fifthImg from "../assets/Images/modals/ZooApp/4-Refining_the_design.webp";
import sixthImg from "../assets/Images/modals/ZooApp/5-next_steps.webp";

export default {
  data() {
    return {
      firstImgLink: firstImg,
      secondImgLink: secondImg,
      thirdImgLink: thirdImg,
      fourthImgLink: fourthImg,
      fifthImgLink: fifthImg,
      sixthImgLink: sixthImg,
    };
  },
};
</script>

<style >
.modal-img {
  width: 100%;
  margin-bottom: -4px;
}
.modal-iframe {
  position: relative;
  height: 90vh;
  width: 100%;
}
</style>