<template>
  <div class="slider-wrapper">
    <!-- Right Arrow -->
    <div class="right-arrow" ref="rArrow">
      <div class="neutral-squircle-btn" @click="scrollToRight">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.49016 20.13L8.27016 21.9L18.1602 12L8.26015 2.1L6.49015 3.86999L14.6202 12L6.49016 20.13Z"
          />
        </svg>
      </div>
    </div>

    <!-- Left Arrow -->
    <div class="left-arrow" ref="lArrow">
      <div class="neutral-squircle-btn" @click="scrollToLeft">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            class="st0"
            d="M10,12l8.1-8.1l-1.8-1.8L6.5,12l9.9,9.9l1.8-1.8L10,12z"
          />
        </svg>
      </div>
    </div>
    <div class="image-container" ref="imgContainer">
      <span class="project-img" v-for="img in projectImgs" :key="img">
        <img :src="img" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["projectImgs"],

  mounted() {
 

    // listening to the scroll in the image container
    const imageContainer = this.$refs.imgContainer;
    const leftArrow = this.$refs.lArrow;
    const rightArrow = this.$refs.rArrow;
    imageContainer.addEventListener(
      "scroll",
      () => {
        var maxScroll = imageContainer.scrollWidth - imageContainer.clientWidth;
        // console.log(maxScroll);

        let currentScroll = imageContainer.scrollLeft;
        // console.log(currentScroll);

        // show the left arrow when neccessary
        if (currentScroll > 0) {
          leftArrow.style.display = "flex";
        } else if (currentScroll == 0) {
          leftArrow.style.display = "none";
        }

        // show the right arrow when neccessary
        if (currentScroll < maxScroll) {
          rightArrow.style.display = "flex";
        } else if (currentScroll >= maxScroll) {
          rightArrow.style.display = "none";
        }
      },
      false
    );
  },

  updated() {
    // resetting the right scroll arrow after every refresh
    this.$refs.rArrow.style.display = "flex";

    //  adding the animation of showing the images
    let imgList = document.querySelectorAll(".project-img");
    for (let i = 0; i < imgList.length; ++i) {
      imgList[i].classList.remove("img-reveall");
      void imgList[i].offsetWidth; //  a trick to restart the animation
      imgList[i].classList.add("img-reveal");
    }
  },

  methods: {
    scrollToRight() {
      const imageContainer = this.$refs.imgContainer;
      var maxScroll = imageContainer.scrollWidth - imageContainer.clientWidth;
      console.log("max scroll " + maxScroll);

      let currentScroll = imageContainer.scrollLeft;
      console.log("current scroll " + currentScroll);

      imageContainer.scroll({
        //   calculating how much scroll we need to do, by calculating the current scroll added to the max width divided by 4 since there is only 4 images, added to 15% of padding of the images.
        left: currentScroll + maxScroll / 4 + (15 / 100) * window.innerWidth,
        top: 0,
        behavior: "smooth",
      });
    },

    scrollToLeft() {
      this.$refs.imgContainer.scroll({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.image-container {
  display: flex;
  overflow: scroll;
  height: 100%;
  padding: 5%;
  padding-left: 10%;
   gap: 5%;
  // scroll-snap-type: x mandatory;
}
.project-img {
  height: 100%;
  // flex-shrink: 0;
  // scroll-snap-align: start;
  img {
    height: 100%;
    opacity: 0;
    display: block;
    animation: appearImg 0.0001s linear forwards;
    animation-delay: 0.5s;
    position: relative;
  }
}
.img-reveal {
  display: inline;
  color: var(--background);
  animation: appearLink 0.0001s linear forwards;
  animation-delay: 0.5s;
  position: relative;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 0%;
    height: 100%;
    background: var(--text-block);
    animation: revBlock 1s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    animation-delay: 0s;
  }
}

@keyframes appearImg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes appearLink {
  0% {
    color: var(--background);
  }
  100% {
    color: var(--header-text);
  }
}

@keyframes revBlock {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 0%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

// image scroll
.slider-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  .right-arrow {
    position: absolute;
    right: -5px;
    top: 8px;
    height: 100%;
    width: 80px;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 2;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;

      top: 0;
      right: 0;
      opacity: 0.5;
      background: linear-gradient(
        270deg,
        var(--background) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .left-arrow {
    display: none;
    position: absolute;
    left: -5px;
    top: 8px;
    height: 100%;
    width: 80px;
    pointer-events: none;
    align-items: center;
    justify-content: center;

    z-index: 2;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;

      top: 0;
      left: 0;
      opacity: 0.5;
      background: linear-gradient(
        90deg,
        var(--background) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .neutral-squircle-btn {
    height: 48px;
    width: 48px;
    background: var(--text-block);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    box-shadow: 0px 3.7px 12.95px -2.775px rgba(24, 39, 75, 0.12),
      0px 8.325px 40.7px -1.85px rgba(24, 39, 75, 0.14);
    svg {
      width: 24px;
      height: 24px;
      fill: var(--text);
    }
    &:active {
      background: var(--background);
    }
  }
}
</style>