<template>
  <section class="container">
    <div class="contact-title">CONTACT</div>
    <div class="social-media-container">
      <a href="https://www.linkedin.com/in/remy-jouni/" target="_blank">
        <img :src="linkedinImg" alt="LinkedIn" class="logo-img" />
      </a>
      <a href="mailto:eng.remi@gmail.com" target="_blank">
        <img :src="mailImg" alt="Mail" class="logo-img" />
      </a>
      <a href="https://www.behance.net/remyjouni" target="_blank">
        <img :src="behanceImg" alt="Behance" class="logo-img" />
      </a>
      <a href="https://twitter.com/RemyJouni" target="_blank">
        <img :src="twitterImg" alt="Twitter" class="logo-img" />
      </a>
    </div>
  </section>
</template>

<script>
import twitter from "../../assets/Images/Social/twitter.svg";
import mail from "../../assets/Images/Social/mail.svg";
import behance from "../../assets/Images/Social/behance.svg";
import linkedin from "../../assets/Images/Social/linkedin.svg";

export default {
  components: {
    twitter,
    mail,
    behance,
    linkedin,
  },
  data() {
    return {
      twitterImg: twitter,
      mailImg: mail,
      behanceImg: behance,
      linkedinImg: linkedin,
    };
  },
};
</script>

 <style lang="scss" scoped>
@import "../../assets/Responsive/breakpoints.scss";

.container {
  // margin-top:20vh;

  width: 100%;
  /* border: 1px solid blue; */
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-content: center;
  background: var(--background);
}

.contact-title {
  font-family: "AvenirHeavy";
  color: var(--text);
  @media (min-width: $mobileS) {
    font-size: 40px;
  }
  @media (min-width: $mobileM) {
    font-size: 50px;
  }
  @media (min-width: $mobileL) {
    font-size: 60px;
  }
  @media (min-width: $tablet) {
    font-size: 90px;
  }
  @media (min-width: $laptop) {
    font-size: 95px;
  }
}

.social-media-container {
  z-index: 1;
  display: grid;
  grid-template: 80px 80px 80px / 1fr 1fr;
  @media (min-width: $mobileS) {
    margin-top: 60px;
    grid-gap: 40px;
  }
  @media (min-width: $mobileM) {
    margin-top: 60px;
    grid-gap: 60px;
  }
  @media (min-width: $mobileL) {
    margin-top: 60px;
    grid-gap: 70px;
  }
  @media (min-width: $tablet) {
    margin-top: 80px;
    grid-gap: 170px;
  }
  @media (min-width: $laptop) {
    margin-top: 120px;
    grid-gap: 200px;
  }
}

.logo-img {
  @media (min-width: $mobileS) {
    height: 65px;
    width: 65px;
  }
  @media (min-width: $mobileM) {
    height: 80px;
    width: 80px;
  }
  @media (min-width: $mobileL) {
    height: 100px;
    width: 100px;
  }
  @media (min-width: $tablet) {
    height: 130px;
    width: 130px;
  }
}
</style>