<template>
  <div class="mobile-screen" v-if="screenWidth < 1022">
    <MobileScreenHero />
    <MobileScreenWork />
    <MobileScreenSkills />
    <MobileScreenContact />
  </div>

  <div class="wide-screen" v-else>
    <WidescreenHero />
    <WidescreenWork />
    <WideScreenSkills />
    <WideScreenContact />
  </div>
</template>

<script>
import WidescreenHero from "./Slides/WideScreen/HeroSlide/Hero.vue";
import WidescreenWork from "./Slides/WideScreen/WorkSlide/Work.vue";
import WideScreenSkills from "./Slides/WideScreen/Skills.vue";
import WideScreenContact from "./Slides/WideScreen/Contact.vue";

import MobileScreenHero from "./Slides/Mobile/HeroSlide/Hero.vue";
import MobileScreenWork from "./Slides/Mobile/WorkSlide/Work.vue";
import MobileScreenSkills from "./Slides/Mobile/Skills.vue";
import MobileScreenContact from "./Slides/Mobile/Contact.vue"

export default {
  name: "App",
  components: {
    WidescreenHero,
    WidescreenWork,
    WideScreenSkills,
    WideScreenContact,
    MobileScreenHero,
    MobileScreenWork,
    MobileScreenSkills,
    MobileScreenContact
  },
  data() {
    return {
      screenWidth: 0,
    };
  },
  mounted() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "AvenirLight";
  src: url("../src/assets/Fonts/Avenir/Avenir-Light-07.ttf");
}

@font-face {
  font-family: "AvenirRoman";
  src: url("../src/assets/Fonts/Avenir/Avenir-Roman-12.ttf");
}

@font-face {
  font-family: "AvenirBook";
  src: url("../src/assets/Fonts/Avenir/Avenir-Book-01.ttf");
}

@font-face {
  font-family: "AvenirMedium";
  src: url("../src/assets/Fonts/Avenir/Avenir-Medium-09.ttf");
}

@font-face {
  font-family: "AvenirHeavy";
  src: url("../src/assets/Fonts/Avenir/Avenir-Heavy-05.ttf");
}

@font-face {
  font-family: "Valencia";
  src: url("../src/assets/Fonts/Valencia/Valencia.ttf");
}

html,
body {
  margin: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --background: #041c32;
  --text-block: hsl(199, 91%, 16%);
  --light-text: #04293a;
  --header-text: #ecb365;
  --text: #fff;
}

body {
  background: var(--background);
  //this is part of scroll code
  scrollbar-width: thin;
  scrollbar-color: hsl(240, 25%, 86%) transparent;
  // overflow: auto !important;

  overflow-x: hidden;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 8px;
    -webkit-appearance: none;
  }

  // &::-webkit-scrollbar-track {
  //   background: var(--light-text);
  //   visibility: hidden;
  // }
  &::-webkit-scrollbar-thumb {
    background-color: var(--header-text);
    border-radius: 8px;
    border: 3px solid transparent;
    transition: 0.2s ease all;
    visibility: hidden;
    &:hover {
      visibility: visible;
    }
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
      transition: 0.2s ease all;
      background-color: var(--text-block);
    }
  }
}

@media (max-width: 1022px) {
  #app,
  .mobile-screen,
  html,
  body {
    height: 100%;
  }
}
</style>
