<template>
  <img class="modal-img" :src="firstImgLink" />
  <img class="modal-img" :src="secondImgLink" loading="lazy" />
  <img class="modal-img" :src="thirdImgLink" loading="lazy" />
  <img class="modal-img" :src="fourthImgLink" loading="lazy" />
  <img class="modal-img" :src="fifthImgLink" loading="lazy" />
  <img class="modal-img" :src="sixthImgLink" loading="lazy" />
</template>

<script>
import firstImg from "../assets/Images/modals/MedicationReminder/0-Splash.webp";
import secondImg from "../assets/Images/modals/MedicationReminder/1-Overview.webp";
import thirdImg from "../assets/Images/modals/MedicationReminder/2-Understanding_the_User.webp";
import fourthImg from "../assets/Images/modals/MedicationReminder/3-Starting_the_design.webp";
import fifthImg from "../assets/Images/modals/MedicationReminder/4-Refining_the_design.webp";
import sixthImg from "../assets/Images/modals/MedicationReminder/5-Going_Forward.webp";

export default {
  data() {
    return {
      firstImgLink: firstImg,
      secondImgLink: secondImg,
      thirdImgLink: thirdImg,
      fourthImgLink: fourthImg,
      fifthImgLink: fifthImg,
      sixthImgLink: sixthImg,
    };
  },
};
</script>

<style scoped>
</style>