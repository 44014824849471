<template>
  <div class="image-container">
    <div class="image-box" :height="boxHeight">
      <MushrfeKiosk
        :boxHeight="boxHeight"
        :index="1"
        :scrollPercent="scrollPercent"
        :screenHeight="screenHeight"
        :scrollHeight="scrollHeight"
      />
    </div>
    <div class="image-box"  :height="boxHeight">
      <ZooApplication
        :boxHeight="boxHeight"
        :index="2"
        :scrollPercent="scrollPercent"
        :screenHeight="screenHeight"
        :scrollHeight="scrollHeight"
      />
    </div>
    <div class="image-box"  :height="boxHeight">
      <MedicationReminder
        :boxHeight="boxHeight"
        :index="3"
        :scrollPercent="scrollPercent"
        :screenHeight="screenHeight"
        :scrollHeight="scrollHeight"
      />
    </div>
    <div class="image-box"  :height="boxHeight">
      <TakaApp
        :boxHeight="boxHeight"
        :index="4"
        :scrollPercent="scrollPercent"
        :screenHeight="screenHeight"
        :scrollHeight="scrollHeight"
      />
    </div>
    <div class="image-box"  :height="boxHeight">
      <TakaWeb
        :boxHeight="boxHeight"
        :index="5"
        :scrollPercent="scrollPercent"
        :screenHeight="screenHeight"
        :scrollHeight="scrollHeight"
      />
    </div>
    <div class="image-box"  :height="boxHeight">
      <Shura
        :boxHeight="boxHeight"
        :index="6"
        :scrollPercent="scrollPercent"
        :screenHeight="screenHeight"
        :scrollHeight="scrollHeight"
      />
    </div>
    <div class="image-box"  :height="boxHeight">
      <Telework
        :boxHeight="boxHeight"
        :index="7"
        :scrollPercent="scrollPercent"
        :screenHeight="screenHeight"
        :scrollHeight="scrollHeight"
      />
    </div>
  </div>
</template>

<script>
import MushrfeKiosk from "./ParallaxImages/MushrfeKiosk.vue";
import ZooApplication from "./ParallaxImages/ZooApplication.vue";
import MedicationReminder from "./ParallaxImages/MedicationReminder.vue";
import TakaApp from "./ParallaxImages/TakaApp.vue";
import TakaWeb from "./ParallaxImages/TakaWeb.vue";
import Shura from "./ParallaxImages/Shura.vue";
import Telework from "./ParallaxImages/Telework.vue";

export default {
  name: "image-content",

  props: { pageSplitTimes: { type: Number, required: true } },

  components: {
    MushrfeKiosk,
    ZooApplication,
    MedicationReminder,
    TakaApp,
    TakaWeb,
    Shura,
    Telework,
  },

  data() {
    return { screenHeight: 0, scrollHeight: 0, scrollPercent: 0, boxHeight: 0 };
  },
  methods: {
    handleScroll() {
      const { body, documentElement } = window.document;
      const sd = Math.max(body.scrollTop, documentElement.scrollTop);
      const sp =
        (sd / (documentElement.scrollHeight - documentElement.clientHeight)) *
        100;
      const minlimit =
        (documentElement.clientHeight * 100) / documentElement.scrollHeight;
      const maxlimit =
        (documentElement.clientHeight * 1180) / documentElement.scrollHeight;

      if (sp >= minlimit && sp <= maxlimit) {
        this.scrollPercent = sp;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.scrollHeight = Math.round(
      window.document.documentElement.scrollHeight
    );
    this.screenHeight = Math.round(
      window.document.documentElement.clientHeight
    );
     this.boxHeight = this.pageSplitTimes * 100;
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

 
};
</script>

<style scoped>
.image-container {
  margin-left: 50%;
  width: 50%;
  height: 1000vh;
  display: flex;
  flex-flow: column nowrap;
  
}
.image-box {
  margin-top: 40vh;
  height: 100vh;
  position: relative;
}
</style>