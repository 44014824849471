<template>
  <img
    :src="firstImgLink"
    alt="home"
    class="first-img"
    :style="{ transform: `translate(0px,-${calculateScroll() * 15}%)` }"
  />
  <img
    :src="secondImgLink"
    alt="New Medication"
    class="second-img"
    :style="{
      transform: `translate(0px,-${calculateScroll() * 8}%) scale(0.9)`,
    }"
  />
  <img
    :src="thirdImgLink"
    alt="Medication Scan"
    class="third-img"
    :style="{
      transform: `translate(0px,-${calculateScroll() * 5}%) scale(0.7)`,
    }"
  />
  <img
    :src="fourthImgLink"
    alt="Medication History"
    class="fourth-img"
    :style="{
      transform: `translate(0px,-${calculateScroll() * 2}%) scale(0.6)`,
    }"
  />
</template>

<script>
import firstImg from "../../../../assets/Images/MedicationReminder/medication-home.webp";
import secondImg from "../../../../assets/Images/MedicationReminder/medication-new.webp";
import thirdImg from "../../../../assets/Images/MedicationReminder/medication-scan.webp";
import fourthImg from "../../../../assets/Images/MedicationReminder/medication-history.webp";

export default {
  name: "medication-reminder",

  components: {
    firstImg,
    secondImg,
    thirdImg,
    fourthImg,
  },
  props: {
    boxHeight: { type: Number, required: true },
    index: { type: Number, required: true },
    screenHeight: { type: Number, required: true },
    scrollHeight: { type: Number, required: true },
    scrollPercent: { type: Number, required: true },
  },
  methods: {
    calculateScroll() {
      let sp = this.scrollPercent;

      const heighttoBeReducedinVH = this.boxHeight * this.index - 100;
      const scrollOffset = (this.screenHeight * heighttoBeReducedinVH) / 100;
      const scrollOffsetInPercent =
        (scrollOffset * 100) / this.scrollHeight + this.index - 1;
      sp -= scrollOffsetInPercent;

      return sp;
    },
  },
  data() {
    return {
      firstImgLink: firstImg,
      secondImgLink: secondImg,
      thirdImgLink: thirdImg,
      fourthImgLink: fourthImg,
    };
  },
};
</script>

<style scoped>
.first-img {
  transition: transform 0.2s ease-out;
  position: absolute;
  bottom: -90vh;
  right: 2vw;

  height: 80vh;
}
.second-img {
  transition: transform 0.2s ease-out;
  position: absolute;
  bottom: -45vh;
  left: 0vw;

  transition: transform 0.2s ease-out;
  height: 80vh;
}

.third-img {
  transition: transform 0.2s ease-out;
  bottom: -75vh;
  right: 5vw;
  position: absolute;
  transition: transform 0.2s ease-out;

  height: 80vh;
}

.fourth-img {
  transition: transform 0.2s ease-out;
  bottom: -55vh;
  left: 5vw;
  left: 5vw;
  transition: transform 0.2s ease-out;
  position: absolute;
  /* border: 1px dashed red; */
  height: 80vh;
}
</style>