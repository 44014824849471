<template>
  <section class="container">
    <div class="about-me-description">
      <p>
        Front-end Developer & UX Designer with 8+ years of experience where I
        designed and developed multiple systems.
      </p>
      <p>
        I have Muscular Dystrophy which forces me to only be able to work
        remotely and part-time. But I won't allow that to hinder me from
        reaching my potential and fulfilling my dreams.
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../../assets/Responsive/breakpoints.scss";

.container {
  //   height: 40vh; /* Since pageSplitTime is 1.4 */
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  background: var(--background);
  justify-content: center;
  align-items: center;
}

.about-me-description {
  font-family: "AvenirRoman";
  font-size: 24px;
  color: var(--text);
  text-align: center;

  @media (min-width: $mobileS) {
    padding: 0 30px;
    font-size: 16px;
  }
  @media (min-width: $mobileM) {
    padding: 0 30px;
    font-size: 18px;
  }
  @media (min-width: $mobileL) {
    padding: 0 30px;
    font-size: 24px;
  }
  @media (min-width: $tablet) {
    padding: 0 80px;
    font-size: 40px;
  }
  @media (min-width: $laptop) {
    padding: 0 90px;
    font-size: 45px;
  }
}
</style>