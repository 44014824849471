<template>
  <img
    :src="firstImgLink"
    alt="home"
    class="first-img"
    :style="{ transform: `translate(0px,-${calculateScroll() * 30}%)` }"
  />
  <img
    :src="secondImgLink"
    alt="Today's Summary"
    class="second-img"
    :style="{
      transform: `translate(0px,-${calculateScroll() * 23}%) scale(0.9)`,
    }"
  />
  <img
    :src="thirdImgLink"
    alt="News Sources"
    class="third-img"
    :style="{
      transform: `translate(0px,-${calculateScroll() * 10}%) scale(0.7)`,
    }"
  />
  <img
    :src="fourthImgLink"
    alt="News Article"
    class="fourth-img"
    :style="{
      transform: `translate(0px,-${calculateScroll() * 3}%) scale(0.7)`,
    }"
  />
</template>

<script>
import firstImg from "../../../../assets/Images/TakaNewsWeb/taka-web-home.webp";
import secondImg from "../../../../assets/Images/TakaNewsWeb/taka-web-sumamry.webp";
import thirdImg from "../../../../assets/Images/TakaNewsWeb/taka-web-sources.webp";
import fourthImg from "../../../../assets/Images/TakaNewsWeb/taka-web-article.webp";

export default {
  name: "taka-website",

  components: {
    firstImg,
    secondImg,
    thirdImg,
    fourthImg,
  },
  props: {
    boxHeight: { type: Number, required: true },
    index: { type: Number, required: true },
    screenHeight: { type: Number, required: true },
    scrollHeight: { type: Number, required: true },
    scrollPercent: { type: Number, required: true },
  },

  methods: {
    calculateScroll() {
      let sp = this.scrollPercent;

      const heighttoBeReducedinVH = this.boxHeight * this.index - 100;
      const scrollOffset = (this.screenHeight * heighttoBeReducedinVH) / 100;
      const scrollOffsetInPercent = (scrollOffset * 100) / this.scrollHeight;
      sp -= scrollOffsetInPercent;

      return sp;
    },
  },
  data() {
    return {
      firstImgLink: firstImg,
      secondImgLink: secondImg,
      thirdImgLink: thirdImg,
      fourthImgLink: fourthImg,
    };
  },
};
</script>

<style scoped>
.first-img {
  transition: transform 0.2s ease-out;
  position: absolute;
  bottom: -176vh;
  left: 0vw;

  width: 40vw;
  height: auto;
}
.second-img {
  position: absolute;
  bottom: -156vh;
  right: 0vw;
  transform-origin: right center;
  transition: transform 0.2s ease-out;

  width: 40vw;
  height: auto;
}

.third-img {
  bottom: -85vh;
  left: -4vw;
  position: absolute;
  transition: transform 0.2s ease-out;
  width: 40vw;
  height: auto;
}

.fourth-img {
  bottom: -42vh;
  right: 1vw;
  transform-origin: right center;
  position: absolute;
  transition: transform 0.2s ease-out;
  width: 40vw;
  height: auto;
}
</style>