<template>
  <div class="stage">
    <div class="text-to-reveal" :class="{ 'type-writing': reveal }">
      Front-end Developer & UX Designer
    </div>
    <div class="shroud"></div>
  </div>
</template>

<script>
export default {
  props: {
    timeDelay: { type: Number, required: true },
  },
  data() {
    return { reveal: false };
  },
  methods: {
    revealText(timeout) {
      setTimeout(() => {
        this.reveal = true;
      }, timeout);
    },
  },
  mounted() {
    const timeDelay = this.timeDelay;
    this.revealText(timeDelay);
  },
};
</script>


<style lang="scss" scoped>
@import "../../../assets/Responsive/breakpoints.scss";
.stage {
  position: relative;
  z-index: 1;
}

.text-to-reveal {
  font-family: "AvenirRoman";
  text-align: center;
  visibility: hidden;
  color: var(--text);
  // margin-bottom: 35vh;
  // text-transform: uppercase;
  // opacity: 0;
  @media (min-width: $tablet) {
    font-size: 28px;
  }

  @media (min-width: $laptop) {
    font-size: 40px;
  }

  @media (min-width: $laptopL) {
    font-size: 50px;
  }

  @media (min-width: $desktop) {
    font-size: 60px;
  }
}

.type-writing {
  overflow: hidden;
  border-right: 0.15em solid var(--header-text);
  white-space: nowrap;
  margin: 0 auto;

  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  visibility: visible;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--header-text);
  }
}
</style>