<template>
  <img :src="firstImgLink" class="modal-img" />
</template>

<script>
import firstImg from "../assets/Images/modals/Taka_Website_Behance.webp";

export default {
  data() {
    return {
      firstImgLink: firstImg,
    };
  },
};
</script>

<style>
</style>