<template>
  <section class="container">
    <div class="contact-title" :style="{ transform: `translateX(${scrollPercent * 8}%)` }">CONTACT</div>
    
    <div class="social-media-icons">
      <a href="https://www.linkedin.com/in/remy-jouni/" target="_blank">
        <img :src="linkedinImg" alt="LinkedIn" class="logo-img" />
      </a>
      <a href="mailto:eng.remi@gmail.com" target="_blank">
        <img :src="mailImg" alt="Mail" class="logo-img" />
      </a>
      <a href="https://www.behance.net/remyjouni" target="_blank">
        <img :src="behanceImg" alt="Behance" class="logo-img" />
      </a>
      <a href="https://twitter.com/RemyJouni" target="_blank">
        <img :src="twitterImg" alt="Twitter" class="logo-img" />
      </a>
    </div>
  </section>
</template>

<script>
import twitter from "../../assets/Images/Social/twitter.svg";
import mail from "../../assets/Images/Social/mail.svg";
import behance from "../../assets/Images/Social/behance.svg";
import linkedin from "../../assets/Images/Social/linkedin.svg";

export default {
  name: "contact",
  components: {
    twitter,
    mail,
    behance,
    linkedin,
  },
  data() {
    return {
      screenHeight: 0,
      scrollHeight: 0,
      scrollPercent: 0,
      twitterImg: twitter,
      mailImg: mail,
      behanceImg: behance,
      linkedinImg: linkedin,
    };
  },
  methods: {
    handleScroll(event) {
      const { body, documentElement } = event.srcElement;
      const sd = Math.max(body.scrollTop, documentElement.scrollTop);
      let sp =
        (sd / (documentElement.scrollHeight - documentElement.clientHeight)) *
        100;
      const minlimit =
        (documentElement.clientHeight * 1040) / documentElement.scrollHeight;

      if (sp >= minlimit && sp <= 100) {
        sp -= minlimit;
        this.scrollPercent = sp;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.scrollHeight = Math.round(
      window.document.documentElement.scrollHeight
    );
    this.screenHeight = Math.round(
      window.document.documentElement.clientHeight
    );
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
 
};
</script>

<style lang="scss" scoped >
@import "../../assets/Responsive/breakpoints.scss";

.container {
  height: 80vh; /* Since pageSplitTime is 1.4 */
  width: 100%;
  /* border: 1px solid blue; */
  position: relative;
  overflow: hidden;
  background: var(--background);
}

.contact-title {
  transition: transform 0.5s ease-out;
  font-family: "AvenirHeavy";
  font-size: 200px;
  position: absolute;
  color: var(--light-text);
  top: 12%;
  left: -70%;
  @media (min-width: $laptop) {
    font-size: 180px;
  }
  @media (min-width: $laptopL) {
    font-size: 200px;
  }
  @media (min-width: $desktop) {
    font-size: 350px;
  }
}

.social-media-icons {
  z-index: 1;
  transform: translateY(210%);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 5vw;
}

.logo-img {
  @media (min-width: $laptop) {
    height: 85px;
    width: 85px;
  }
  @media (min-width: $laptopL) {
    height: 90px;
    width: 90px;
  }
  @media (min-width: $desktop) {
    height: 180px;
    width: 180px;
  }
}
</style>