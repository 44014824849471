<template>
  <section class="container">
    <div
      class="skills-title"
      :style="{ transform: `translateX(-${scrollPercent * 10}%)` }"
    >
      SKILLS
    </div>
    <div class="skills-list">
      <div>
        HTML/CSS
        <br />
        Javascript
        <br />
        React JS
        <br />
        Next JS 13
        <br />
        VueJS
        <br />
        Tailwind CSS
        <br />
      </div>
      <div>
        Figma
        <br />
        Adobe XD
        <br />
        Adobe Photoshop
        <br />
        Adobe Illustrator
        <br />
        Adobe InDesign
        <br />
        Adobe After Effects
        <br />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "skills",
  data() {
    return { screenHeight: 0, scrollHeight: 0, scrollPercent: 0 };
  },
  methods: {
    handleScroll(event) {
      const { body, documentElement } = event.srcElement;
      const sd = Math.max(body.scrollTop, documentElement.scrollTop);
      let sp =
        (sd / (documentElement.scrollHeight - documentElement.clientHeight)) *
        100;
      const minlimit =
        (documentElement.clientHeight * 950) / documentElement.scrollHeight;
      const maxlimit =
        (documentElement.clientHeight * 1180) / documentElement.scrollHeight;

      if (sp >= minlimit && sp <= maxlimit + 3) {
        sp -= minlimit;
        this.scrollPercent = sp;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.scrollHeight = Math.round(
      window.document.documentElement.scrollHeight
    );
    this.screenHeight = Math.round(
      window.document.documentElement.clientHeight
    );
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  //   render() {
  //     const { scrollPercent } = this;
  //     return (
  //       <container>
  //         <skills-title scrollPercent={scrollPercent}>SKILLS</skills-title>
  //         <skills-list>
  //           <div>
  //             HTML/CSS
  //             <br />
  //             Javascript
  //             <br />
  //             VueJS
  //             <br />
  //             Angular 2+
  //             <br />
  //             Ionic
  //             <br />
  //             Bootstrap
  //             <br />
  //           </div>
  //           <div>
  //             Figma
  //             <br />
  //             Adobe XD
  //             <br />
  //             Adobe Photoshop
  //             <br />
  //             Adobe Illustrator
  //             <br />
  //             Adobe InDesign
  //             <br />
  //             Adobe After Effects
  //             <br />
  //           </div>
  //         </skills-list>
  //       </container>
  //     );
  //   },
};
</script>

<style lang="scss" scoped>
@import "../../assets/Responsive/breakpoints.scss";

.container {
  height: 120vh; /* Since pageSplitTime is 1.4 */
  width: 100%;
  /* border: 1px solid blue; */
  position: relative;
  overflow: hidden;
  background: var(--background);
}

.skills-title {
  transition: transform 0.5s ease-out;
  font-family: "AvenirHeavy";
  position: absolute;
  color: var(--light-text);
  top: 40%;
  right: -50%;
  @media (min-width: $laptop) {
    font-size: 180px;
  }
  @media (min-width: $laptopL) {
    font-size: 200px;
  }
  @media (min-width: $desktop) {
    font-size: 350px;
  }
}

.skills-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: "AvenirRoman";
  text-align: left;
  margin-left: 15%;
  margin-right: 10%;
  z-index: 1;
  color: var(--text);
  transform: translateY(30%);
  @media (min-width: $laptop) {
    font-size: 35px;
  }
  @media (min-width: $laptopL) {
    font-size: 40px;
  }
  @media (min-width: $desktop) {
    font-size: 70px;
  }
}
</style>