<template>
  <section class="container">
    <div class="skills-title">SKILLS</div>
    <div class="skills-list">
      <div>
        HTML/CSS
        <br />
        Javascript
        <br />
        React JS
        <br />
        Next JS 13
        <br />
        VueJS
        <br />
        Tailwind CSS
        <br />
      </div>
      <div>
        <br />
        Figma
        <br />
        Adobe XD
        <br />
        Adobe Photoshop
        <br />
        Adobe Illustrator
        <br />
        Adobe InDesign
        <br />
        Adobe After Effects
        <br />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "../../assets/Responsive/breakpoints.scss";

.container {
  height: 100%;
  width: 100%;
  /* border: 1px solid blue; */
  padding-top:32px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-content: flex-start;
  background: var(--background);
  position: relative;
  z-index: 2;
  @media (min-width: $mobileS) {
    padding-left: 60px;
  }
  @media (min-width: $mobileM) {
    padding-left: 60px;
  }
  @media (min-width: $mobileL) {
    padding-left: 60px;
  }
  @media (min-width: $tablet) {
    padding-left: 90px;
  }
  @media (min-width: $laptop) {
    padding-left: 120px;
  }
}

.skills-title {
  font-family: "AvenirHeavy";
  color: var(--text);
  @media (min-width: $mobileS) {
    font-size: 40px;
  }
  @media (min-width: $mobileM) {
    font-size: 50px;
  }
  @media (min-width: $mobileL) {
    font-size: 60px;
  }
  @media (min-width: $tablet) {
    font-size: 90px;
  }
  @media (min-width: $laptop) {
    font-size: 95px;
  }
}

.skills-list {
  font-family: "AvenirRoman";
  z-index: 1;
  color: var(--text);

  @media (min-width: $mobileS) {
    margin-top: 30px;
    font-size: 20px;
  }
  @media (min-width: $mobileM) {
    margin-top: 35px;
    font-size: 23px;
  }
  @media (min-width: $mobileL) {
    margin-top: 35px;
    font-size: 25px;
  }
  @media (min-width: $tablet) {
    margin-top: 45px;
    font-size: 35px;
  }
  @media (min-width: $laptop) {
    margin-top: 60px;
    font-size: 45px;
  }
}
</style>