<template>
  <NameAndJob />
  <AboutMe />
</template>

<script>
import NameAndJob from "./NameAndJobTitle.vue";
import AboutMe from "./AboutMe.vue";
export default {
  components: {
    NameAndJob,
    AboutMe,
  },
};
</script>

<style>

</style>