<template>
  <section class="work-container">
    <TextContent
      :number="workDetails[slideNumber].number"
      :projectName="workDetails[slideNumber].projectName"
      :projectDesc="workDetails[slideNumber].projectDesc"
      :LinkText="workDetails[slideNumber].LinkText"
      :LinkURL="workDetails[slideNumber].LinkURL"
      :projectType="workDetails[slideNumber].projectType"
      :roles="workDetails[slideNumber].roles"
      :projectImgs="workDetails[slideNumber].projectImgs"
      :modal="workDetails[slideNumber].modal"
    />

    <div class="scroll-height"></div>
  </section>
</template>

<script>
import TextContent from "./TextContent.vue";

import CV from "../../../assets/Documents/Remy_Jouni_CV.pdf";
import MushrfeBooklet from "../../../assets/Documents/Mushrfe_Booklet.pdf";

import MushrfeLogin from "../../../assets/Images/Mobile/MushrfeKiosk/Mushrfe-login.webp";
import MushrfeDashboard from "../../../assets/Images/Mobile/MushrfeKiosk/Mushrfe-dashboard.webp";
import MushrfeLeaveRequest from "../../../assets/Images/Mobile/MushrfeKiosk/Mushrfe-leave-reqeust.webp";
import MushrfeStatusStatistics from "../../../assets/Images/Mobile/MushrfeKiosk/Mushrfe-status-statistics.webp";

import ZooHome from "../../../assets/Images/Mobile/ZooApp/zoo-home.webp";
import ZooAnimalDescription from "../../../assets/Images/Mobile/ZooApp/zoo-animal-description.webp";
import ZooAnimal from "../../../assets/Images/Mobile/ZooApp/zoo-animals.webp";
import ZooDirections from "../../../assets/Images/Mobile/ZooApp/zoo-directions.webp";

import medicationHome from "../../../assets/Images/Mobile/MedicationReminder/medication-home.webp";
import medicationNew from "../../../assets/Images/Mobile/MedicationReminder/medication-new.webp";
import medicationScan from "../../../assets/Images/Mobile/MedicationReminder/medication-scan.webp";
import medicationHistory from "../../../assets/Images/Mobile/MedicationReminder/medication-history.webp";

import TakaWelcomeApp from "../../../assets/Images/Mobile/TakaNewsApp/taka-welcome.webp";
import TakaHomeApp from "../../../assets/Images/Mobile/TakaNewsApp/taka-home.webp";
import TakaReadApp from "../../../assets/Images/Mobile/TakaNewsApp/taka-read-news.webp";
import TakaSettingsApp from "../../../assets/Images/Mobile/TakaNewsApp/taka-settings.webp";

import TakaHomeWeb from "../../../assets/Images/Mobile/TakaNewsWeb/taka-web-home.webp";
import TakaSummaryWeb from "../../../assets/Images/Mobile/TakaNewsWeb/taka-web-sumamry.webp";
import TakaSourcesWeb from "../../../assets/Images/Mobile/TakaNewsWeb/taka-web-sources.webp";
import TakaArticleWeb from "../../../assets/Images/Mobile/TakaNewsWeb/taka-web-article.webp";

import ShuraFiles from "../../../assets/Images/Mobile/Shura/shura-files.webp";
import ShuraChat from "../../../assets/Images/Mobile/Shura/shura-chat.webp";
import ShuraEditPage from "../../../assets/Images/Mobile/Shura/shura-edit-page.webp";
import ShuraPerformance from "../../../assets/Images/Mobile/Shura/shura-member-performance.webp";

import TeleworkTimer from "../../../assets/Images/Mobile/TeleworkWeb/telework-timer.webp";
import TeleworkChat from "../../../assets/Images/Mobile/TeleworkWeb/telework-chat.webp";
import TeleworkVOIP from "../../../assets/Images/Mobile/TeleworkWeb/telework-conference.webp";
import TeleworkCalendar from "../../../assets/Images/Mobile/TeleworkWeb/telework-calendar.webp";

export default {
  name: "work",
  components: {
    TextContent,
  },
  data() {
    return { vh: 0, slideNumber: 0 };
  },
  methods: {
    handleScroll(event) {
      const { body, documentElement } = event.srcElement;
      const { vh, slideNumber } = this;
      const scrollDistance = Math.max(
        body.scrollTop,
        documentElement.scrollTop
      );

      if (scrollDistance > this.lastScrollTop) {
        this.scrollDirectionDown = true;
      } else {
        this.scrollDirectionDown = false;
      }

      this.lastScrollTop = scrollDistance;

      // console.log(scrollDistance);
      if (
        Math.floor(scrollDistance / vh) !== slideNumber &&
        slideNumber < this.workDetails.length - 1
      ) {
        this.slideNumber = Math.floor(scrollDistance / vh);
      } else if (
        slideNumber === this.workDetails.length - 1 &&
        Math.floor(scrollDistance / vh) < slideNumber
      ) {
        this.slideNumber = Math.floor(scrollDistance / vh);
      }
    },
  },
  created() {
    this.pageSplitTimes = 0.7;
    this.lastScrollTop = 0;
    this.scrollDirectionDown = true;
    this.workDetails = [
      {
        number: "themeToggle",
        projectName: "",
        projectDesc: "",
        projectType: "",
        LinkText: "Download CV",
        LinkURL: CV,
        projectImgs: [""],
        roles: [""],
        modal: false,
      },
      {
        number: "01",
        projectName: "Mushrfe Kiosk",
        projectDesc:
          "An app for a self-service kiosk machine that enables the desk-less workforce to communicate directly with HR department to apply for requests and receive services in return.",
        LinkText: "Download Brochure",
        LinkURL: MushrfeBooklet,
        projectImgs: [
          MushrfeLogin,
          MushrfeDashboard,
          MushrfeLeaveRequest,
          MushrfeStatusStatistics,
        ],

        projectType: "WEB APP",
        roles: ["UI Designer"],
        modal: false,
      },
      {
        number: "02",
        projectName: "Zoo Application",
        projectDesc:
          "iOS app concept with a map interface that helps visitors get directions to animals, places to eat, and other places of interest.",
        LinkText: "View Case Study",
        LinkURL: "https://www.behance.net/gallery/130534477/Zoo-App-Case-Study",
        projectImgs: [ZooHome, ZooAnimalDescription, ZooAnimal, ZooDirections],

        projectType: "iOS APP",
        roles: ["UX Designer"],
        modal: true,
      },
      {
        number: "03",
        projectName: "Medication Reminder",
        projectDesc:
          "Android app concept to remind people to take their medication on time, and to manage their inventory of medicine. ",
        LinkText: "View Case Study",
        LinkURL:
          "https://www.behance.net/gallery/131314293/Medication-Reminder-Case-Study",
        projectImgs: [
          medicationHome,
          medicationNew,
          medicationScan,
          medicationHistory,
        ],

        projectType: "WEB APP",
        roles: ["UX Designer"],
        modal: true,
      },
      {
        number: "04",
        projectName: "Taka News App",
        projectDesc:
          "News aggregation app for the Arab-World that provide users with articles that interestes them.",
        LinkText: "View Project",
        LinkURL: "https://www.behance.net/gallery/89761985/Taka-News-App",
        projectImgs: [
          TakaWelcomeApp,
          TakaHomeApp,
          TakaReadApp,
          TakaSettingsApp,
        ],

        projectType: "Android / iOS APP",
        roles: ["Front-end Developer", "UX Designer"],
        modal: true,
      },
      {
        number: "05",
        projectName: "Taka News Website",
        projectDesc:
          "News aggregation website for the Arab World with a similar feature set as the application previously mentioned.",
        LinkText: "View Project",
        LinkURL: "https://www.behance.net/gallery/91337175/Taka-News-Website",
        projectImgs: [
          TakaHomeWeb,
          TakaSummaryWeb,
          TakaSourcesWeb,
          TakaArticleWeb,
        ],

        projectType: "WEB APP",
        roles: ["Front-end Developer", "UX Designer"],
        modal: true,
      },
      {
        number: "06",
        projectName: "Shura System",
        projectDesc:
          "A knowledge base system with communication and file management features that enables users to collaborate in the best way possible",
        LinkText: "View Project",
        LinkURL: "https://www.behance.net/gallery/89826353/SHURA-SYSTEM-v80",
        projectImgs: [ShuraChat, ShuraFiles, ShuraEditPage, ShuraPerformance],

        projectType: "WEB APP",
        roles: ["Front-end Developer", "UX Designer"],
        modal: true,
      },
      {
        number: "07",
        projectName: "Telework Pro",
        projectDesc:
          "A communicating and time tracking system that empowers users while working remotely. ",
        projectImgs: [
          TeleworkTimer,
          TeleworkChat,
          TeleworkVOIP,
          TeleworkCalendar,
        ],

        LinkText: "",
        LinkURL: "",
        projectType: "WEB APP",
        roles: ["Front-end Developer", "UX Designer"],
        modal: false,
      },
      {
        number: "",
        projectName: "",
        projectDesc: "",
        LinkText: "",
        LinkURL: "",
        projectImgs: [""],

        projectType: "",
        roles: [""],
        modal: false,
      },
    ];
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.vh = Math.round(
      window.document.documentElement.clientHeight * this.pageSplitTimes
    );
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.work-container {
  display: flex;
  flex-flow: row nowrap;
  background: var(--background);
  height: fit-content;
}
.scroll-height {
  width: 100%;
  height: 525vh;
  margin-bottom: 30%;
 

}
</style>